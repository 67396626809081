<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :mode="passiveAggressive">
    <label v-if="label" :for="id">{{ label }} <span class="inline-help required" v-if="required && !disabled">*</span><slot name="labelDescription"></slot></label>

    <slot name="description"></slot>

    <div :class="{ 'percentage-input-wrapper': inputType === 'percentage' }">
      <input
        v-if="maxlength"
        :type="type"
        :id="id"
        :data-testid="id"
        :name="inputName"
        :placeholder="placeholder"
        :class="['form-control', { 'has-error': errors.length, 'disabled': disabled }]"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @input="onInput($event.target.value)"
        :value="$attrs.value"
        :maxlength="maxlength">

      <input
        v-else
        :type="type"
        :id="id"
        :data-testid="id"
        :name="inputName"
        :placeholder="placeholder"
        :class="['form-control', { 'has-error': errors.length, 'disabled': disabled }]"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @input="onInput($event.target.value)"
        :value="$attrs.value">
    </div>

    <div v-if="errors.length && showErrorText">
      <slot name="errors" :errors="errors[0]">
        <div class="error-text top-5">
          {{ errors[0] }}
        </div>
      </slot>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

import { isString } from 'lodash';

export default {
  name: 'TextInputVertical',

  components: {
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: false
    },

    id: {
      type: String,
      required: false
    },

    inputType: {
      type: String,
      default: 'text'
    },

    inputName: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    min: {
      type: Number,
      required: false
    },

    max: {
      type: Number,
      required: false
    },

    step: {
      type: Number,
      required: false
    },

    showErrorText: {
      type: Boolean,
      default: true
    },

    autocomplete: {
      type: String,
      required: false
    },

    rules: {
      type: [String, Object],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    maxlength: {
      type: Number,
      required: false
    }
  },

  computed: {
    required: function () {
      if (isString(this.rules)) {
        return this.rules.includes('required');
      }
      else if (typeof this.rules === 'object' && this.rules !== null) {
        return this.rules?.required;
      }

      return false;
    },

    type: function () {
      const inputTypeMap = { percentage: 'number' };

      return inputTypeMap[this.inputType] || this.inputType;
    }
  },

  methods: {
    onInput: function (value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .percentage-input-wrapper {
    position: relative;

    input[type="number"] {
      padding-right: 25px;

      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
      }
    }

    &::after {
      content: "%";
      position: absolute;
      top: 13px;
      right: 10px;
      color: $pill-gray;
      pointer-events: none;

      @media (min-width: 768px) {
        top: 11px
      }
    }
  }
</style>
