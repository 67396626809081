<template>
  <tooltip :text="tooltipText" trigger="manual" custom-class="k-tooltip over-modal" placement="bottom" v-model="showTooltip">
    <a href="" ref="copyLink" v-clipboard:copy="textToCopy" v-clipboard:success="copied" @click.prevent><slot></slot></a>
  </tooltip>
</template>

<script>
export default {
  name: 'CopyToClipboard',

  props: {
    textToCopy: {
      type: String,
      required: true
    },

    tooltipText: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showTooltip: false
    };
  },

  methods: {
    copied() {
      const self = this;

      self.showTooltip = true;
      setTimeout(() => { self.showTooltip = false; }, 1400);
    }
  }
};
</script>
