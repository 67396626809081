let uivScrollableDropdown = {
  inserted: function (el) {
    let parent = el.parentElement;

    parent.classList.add('scrolled-up');

    el.addEventListener('scroll', function () {
      let bottomOffset = this.scrollHeight - parent.clientHeight - 1;

      if (this.scrollTop > 0) {
        parent.classList.add('scrolled-down');
      }
      else {
        parent.classList.remove('scrolled-down');
      }

      if (this.scrollTop > bottomOffset) {
        parent.classList.remove('scrolled-up');
      }
      else {
        parent.classList.add('scrolled-up');
      }
    });
  },

  update: function (el, binding) {
    let parent = el.parentElement;
    const maxHeight = 280;

    if (binding.value && parent.clientHeight >= maxHeight) {
      parent.classList.add('with-scrollable');
    }
    else {
      parent.classList.remove('with-scrollable');
    }
  }
};

export default uivScrollableDropdown;
