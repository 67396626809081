<template>
  <section class="uiv">
    <slot name="modal-trigger" :open-modal="openModal"></slot>

    <modal :id="modalId" v-model="modalOpen" :size="modalSize" @hide="emitCloseEvent" :header="false" :footer="false" :backdrop="backdrop" :keyboard="backdrop" append-to-body>
      <div class="close-modal full-screen" data-toggle="modal" :data-target="modalId" @click="dismissModal">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <template slot="default">
        <div class="notice-modal">
          <div class="notice-header">
            <slot name="title">
              {{ title }}
            </slot>
          </div>

          <div class="notice-regular-text">
            <slot :close-modal="closeModal"></slot>
          </div>
        </div>
      </template>
    </modal>
  </section>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'PrioriModal',

  components: {
    SvgIcon
  },

  props: {
    modalId: {
      type: String,
      required: false
    },

    modalSize: {
      type: String,
      default: 'md-sm'
    },

    /* Modal title can be passed in as a string prop, or dynamically created
       using HTML by using a slot, ie:

      <template #title>
        Marketplace {{ accountType }}: <span class="purple-text">{{ name }}</span>
      </template>
    */

    title: {
      type: String,
      required: false
    },

    backdrop: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      modalOpen: false,
      closeMessage: 'done'
    };
  },

  methods: {
    openModal() {
      this.modalOpen = true;
      // Using uiv's @show to emit our `on-modal-open` event seems to cause problems with Jest.
      //   Instead, we can emit our event here.
      this.$emit('on-modal-open');
    },

    closeModal(message) {
      this.closeMessage = (message || 'done');
      this.modalOpen = false;
    },

    dismissModal(message) {
      this.closeMessage = (message || 'dismiss');
      this.modalOpen = false;
    },

    emitCloseEvent() {
      this.$emit('on-modal-close', this.closeMessage);
    }
  }
};
</script>

<style lang="scss" scoped>
  .notice-modal .notice-header {
    height: auto;
  }
</style>
