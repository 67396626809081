<template>
  <priori-modal ref="modal" @on-modal-close="reset">
    <template #modal-trigger>
      <slot name="modal-trigger" :open-modal="openModal"></slot>
    </template>

    <template #title>
      <div class="row">
        <div class="col-xs-5 text-left">
          Add User
        </div>

        <div class="col-xs-7 text-right">
          <copy-to-clipboard
            v-if="!ssoEnabled"
            :text-to-copy="inviteUrl"
            tooltip-text="Link copied to clipboard">
            Get Shareable Link
          </copy-to-clipboard>
        </div>
      </div>
    </template>

    <template #default="{ closeModal }">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form role="form" name="form" class="a-form" novalidate>
          <div class="bottom-30">
            <text-input-vertical
              v-model="client.email"
              label="Email Address"
              id="email"
              input-name="email"
              :placeholder="`Ex: jane.doe@${emailDomain}`"
              rules="required|email">
            </text-input-vertical>
          </div>

          <div class="row">
            <div class="col-sm-6 bottom-30">
              <text-input-vertical
                v-model="client.firstName"
                label="First Name"
                id="first-name"
                input-name="firstName"
                placeholder="Ex: Jane"
                rules="required">
              </text-input-vertical>
            </div>

            <div class="col-sm-6 bottom-30">
              <text-input-vertical
                v-model="client.lastName"
                label="Last Name"
                id="last-name"
                input-name="lastName"
                placeholder="Ex: Doe"
                rules="required">
              </text-input-vertical>
            </div>
          </div>

          <div>
            <text-input-vertical
              v-model="client.role"
              label="Job Title"
              id="role"
              input-name="role"
              placeholder="Ex: Associate GC, Director of Legal Operations, etc.">
            </text-input-vertical>
          </div>

          <div class="row tight-columns top-30">
            <div class="col-sm-4">
              <loading-button name="sendInvite" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(sendInvite)">Add User</loading-button>
            </div>

            <div class="col-sm-3 top-20-xs">
              <button type="button" class="nv-button-white a-button-size" @click="closeModal">Cancel</button>
            </div>
          </div>

          <div class="error-text top-10" v-if="responseError">
            <div class="size-text-12px">
              <span>{{ responseError }}</span>
            </div>
          </div>
        </form>
      </validation-observer>
    </template>
  </priori-modal>
</template>

<script>
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import CopyToClipboard from 'vue-app/shared/components/copy-to-clipboard.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import NotificationService from 'vue-app/shared/services/notification-service';
import Client from 'resources/client';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'WorkspaceInviteModal',

  components: {
    CopyToClipboard,
    LoadingButton,
    PrioriModal,
    ValidationObserver,
    TextInputVertical
  },

  mixins: [
    interactionModes
  ],

  props: {
    currentUser: {
      type: Object,
      default: () => ({})
    },

    workspace: {
      type: Object,
      required: true
    },

    onInviteSent: {
      type: Function
    }
  },

  data() {
    return {
      client: this.buildClient(),
      responseError: ''
    };
  },

  computed: {
    currentPath() {
      return window.location.pathname;
    },

    addingToRequest() {
      return (this.currentPath || '').includes('client-app/requests');
    },

    emailDomain() {
      return (this.currentUser?.email || '').split('@')[1];
    },

    inviteUrl() {
      return window.location.origin + '/join-workspace/' + this.workspace.uid;
    },

    ssoEnabled() {
      return this.workspace.ssoEnabled;
    }
  },

  methods: {
    buildClient() {
      return new Client({
        workspaceUid: this.workspace.uid,
        invitedByType: this.currentUser.klass,
        invitedById: this.currentUser.id
      });
    },

    reset() {
      this.client = this.buildClient();
      this.responseError = '';
      this.$refs.observer.reset();
    },

    sendInvite() {
      LoadingService.loading('sendInvite');

      if (this.addingToRequest) {
        this.client.skipConfirmation = true;
      }

      return Client.save({ client: this.client, completing_sign_up: true })
        .then((savedClient) => {
          // This message is overridden if this user is immediately added to a matter or request.
          NotificationService.success('You successfully added a new user to your team. The user will be notified by email and can request access to your team’s projects.');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.reset();
          this.$refs.modal.closeModal();
          this.onInviteSent(savedClient);
        })
        .catch(() => {
          this.responseError = 'The selected person already has an account with Priori and cannot be added as a new user. Please contact Priori for further information.';
        })
        .finally(() => {
          LoadingService.done('sendInvite');
        });
    },

    openModal() {
      this.$refs.modal.openModal();
    }
  }
};
</script>
