<template>
  <modal v-model="modalOpen" :header="false" :footer="false" @show="initModal" @hide="modalClosing" append-to-body>
    <div class="send-message-modal normal-weight">
      <div class="close-modal full-screen" @click="dismissModal()">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <div class="send-message-modal-inner" v-if="!composing">
        <div class="bottom-20 text-center size-text-20px">
          Message Proposal Lawyers
        </div>

        <div class="recipients-list-item" v-for="recipient in recipients">
          <div class="row tight-columns">
            <div class="col-xs-8 col-sm-9 text-ellipsis photo-overflow-container">
              <profile-photo :user="recipient" :small="true"></profile-photo>
              <span class="semibold-weight size-text-13px left-10">{{ recipientNamePresentation(recipient) }}</span>
            </div>

            <div class="col-xs-4 col-sm-3 text-right">
              <button type="button" :class="['toggle-button blue-toggle', { 'toggle-on': recipientSelected(recipient) }]" @click="toggleSelectRecipient(recipient)"></button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 col-sm-offset-3 top-10">
            <button type="button" class="nv-button-white smaller" @click="openComposer()">Compose Message</button>
          </div>
        </div>
      </div>

      <div class="send-message-modal-inner" v-if="!submitted && composing">
        <div class="text-center bottom-10">
          <div class="photo-container single" v-if="selectedRecipients.length === 1">
            <profile-photo :user="selectedRecipients[0]" :small="true"></profile-photo>
          </div>

          <div class="photo-container multiple" v-if="selectedRecipients.length > 1">
            <div class="inner-container first">
              <profile-photo :user="selectedRecipients[0]" :smallest="true"></profile-photo>
            </div>

            <div class="inner-container second">
              <profile-photo :user="selectedRecipients[1]" :smallest="true"></profile-photo>
            </div>
          </div>
        </div>

        <div class="size-text-20px text-center bottom-20">
          Message to {{ recipientsList.join(', ') }}
        </div>

        <form role="form" name="sendMessage" id="send-message-form" class="a-form">
          <div class="bottom-20">
            <textarea class="form-control" id="send-message-reply" placeholder="Enter message..." rows="6" v-model="message.body"></textarea>
          </div>

          <div class="message-upload-container visible" v-show="message.attachments.length || message.uploading.length || message.errors.length">
            <div class="message-upload" v-for="attachment in message.attachments">
              <div class="message-upload-inner">
                <div class="text-ellipsis semibold-weight">
                  <svg-icon class="base-icon right-5" name="attachment"></svg-icon><span class="semibold-weight size-text-13px">{{ attachment.name }}</span>
                </div>

                <div @click="removeAttachment(attachment)">
                  <svg-icon class="base-icon smallest" name="x3"></svg-icon>
                </div>
              </div>

              <div class="progress-container"></div>
            </div>

            <div class="message-upload" v-for="attachment in message.uploading">
              <div class="message-upload-inner disabled">
                <div class="text-ellipsis semibold-weight">
                  <svg-icon class="base-icon right-5" name="attachment"></svg-icon><span class="semibold-weight size-text-13px">{{ attachment.name }}</span>
                </div>

                <div>
                  <svg-icon class="base-icon smallest" name="x3"></svg-icon>
                </div>
              </div>

              <div class="progress-container" :style="`width: ${attachment.progress}%;`"></div>
            </div>

            <div class="bottom-10" v-for="error in message.errors">
              <span class="semibold-weight red-text">Error uploading {{ error.fileName }}<span v-if="error.message.length > 0"> - {{ error.message }}</span></span>
            </div>
          </div>

          <div class="row tight-columns top-20">
            <div class="col-xs-3 col-sm-2">
              <input
                v-show="false"
                ref="selectInput"
                type="file"
                multiple="true"
                max-size="'5MB'"
                accept=".jpg,.jpeg,.gif,.png,.bmp,.pdf,.doc,.docx,.txt,.odt,.rtf"
                :disabled="!message.body.length || uploading || isLoading || multiMessage"
                @change="attachFiles($event)">

              <button
                class="nv-button-white smaller"
                @click.prevent="openFileSelect()"
                :disabled="!message.body.length || uploading || isLoading || multiMessage">
                <svg-icon name="attachment" class="base-icon smaller"></svg-icon>
              </button>
            </div>

            <div class="col-xs-9 col-sm-5 col-sm-offset-5">
              <button type="submit" class="nv-button-blue smaller" :disabled="!message.body.length || uploading || isLoading" @click.prevent="submit()">Send Message<span class="spinner left-10" v-show="isLoading"></span></button>
            </div>
          </div>
        </form>
      </div>

      <div class="send-message-modal-inner" v-if="submitted && composing">
        <div class="text-center bottom-10">
          <div class="photo-container single" v-if="selectedRecipients.length === 1">
            <profile-photo :user="recipients[0]" :small="true"></profile-photo>
          </div>

          <div class="photo-container multiple" v-if="selectedRecipients.length > 1">
            <div class="inner-container first">
              <profile-photo :user="selectedRecipients[0]" :smallest="true"></profile-photo>
            </div>

            <div class="inner-container second">
              <profile-photo :user="selectedRecipients[1]" :smallest="true"></profile-photo>
            </div>
          </div>
        </div>

        <div class="bottom-20 text-center size-text-20px">
          Message to {{ recipientsList.join(', ') }}
        </div>

        <hr class="kenny-hr">

        <div class="text-center bottom-10">
          {{ successMessage }}
        </div>

        <div class="row">
          <div class="col-sm-6 col-sm-offset-3 top-10">
            <button type="button" class="nv-button-white smaller" @click="dismissModal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ProfilePhoto from 'vue-app/shared/components/profile-photo.vue';

import modalToggle from 'vue-app/shared/mixins/modal-toggle';

import Message          from 'resources/marketplace/message.js';
import MessagingService from 'services/marketplace/messaging-service.js';

import { vPull }                      from 'misc/vue-helpers';
import { clone, each, includes, map } from 'lodash';

export default {
  name: 'SendMessageModal',

  components: {
    SvgIcon,
    ProfilePhoto
  },

  mixins: [
    modalToggle
  ],

  props: {
    recipients: {
      type:     Array,
      required: true
    },

    onSend: {
      type:    Function,
      default: () => {}
    },

    multiMessage: {
      type:    Boolean,
      default: false
    },

    showModal: {
      type:    Boolean,
      default: false
    }
  },

  data() {
    return {
      message:            new Message(),
      submitted:          false,
      composing:          !this.multiMessage,
      isLoading:          false,
      uploading:          false,
      selectedRecipients: clone(this.recipients)
    };
  },

  computed: {
    recipientsList() {
      if (this.multiMessage) { return map(this.selectedRecipients, 'firstName'); }

      return map(this.selectedRecipients, (rec) => `${rec.firstName} ${rec.lastName}`);
    },

    formattedRecipients() {
      return map(this.selectedRecipients, rec => `${rec.klass}-${rec.id}`);
    },

    successMessage() {
      const fragments = ['Your message has been sent successfully.'];

      if (this.recipients.length === 1 && this.recipients[0].klass === 'PotentialClient') {
        fragments.push(`${this.recipients[0].firstName} will receive it after confirming their account. `);
      }

      fragments.push('You can access your messages from your dashboard and will receive an email alerting you when you receive a response.');

      return fragments.join(' ');
    }
  },

  // TODO: the showModal prop and this watch on the prop are only needed so
  // that the modal can be triggered from Angular. When we don't use this with
  // Angular anymore, both the prop and the watch can be removed.
  watch: {
    showModal(open) {
      if (open) {
        this.modalOpen = true;
      }
      else {
        this.modalOpen = false;
      }
    },

    recipients(newVal) {
      this.selectedRecipients = clone(newVal);
    }
  },

  methods: {
    initModal() {
      document.getElementById('send-message-reply').focus();
    },

    modalClosing() {
      this.submitted = false;
      this.composing = !this.multiMessage;
      this.message = new Message();
      this.$emit('close');
    },

    toggleSelectRecipient(recipient) {
      if (includes(this.selectedRecipients, recipient)) {
        vPull(this.selectedRecipients, recipient);
      }
      else {
        this.selectedRecipients.push(recipient);
      }
    },

    recipientSelected(recipient) {
      return includes(this.selectedRecipients, recipient);
    },

    openComposer() {
      this.composing = true;
    },

    resizeEditor() {
      const containerHeight = $('.expandable-input').height();
      const attachmentsHeight = $('.message-upload-container:first').outerHeight();

      $('textarea:first').height(containerHeight - attachmentsHeight);
    },

    recipientNamePresentation(rec) {
      if (rec.lawFirmName) {
        return `${rec.fullName} (${rec.lawFirmName})`;
      }
      else {
        return rec.fullName;
      }
    },

    attachFiles(event) {
      this.uploading = true;

      const files        = [];
      const invalidFiles = [];

      each(event.target.files, (file) => {
        // disqualify files over 5MB
        if ((file.size / 1048576) > 5) {
          invalidFiles.push(file);
        }
        else {
          files.push(file);
        }
      });

      each(invalidFiles, (file) => {
        this.message.errors.push({
          fileName: file.name,
          message: 'Must be under 5MB'
        });
      });

      this.resizeEditor();

      this.message.multiUpload(files).then(() => {
        this.uploading = false;
        this.resizeEditor();
      });
    },

    removeAttachment(attachment) {
      this.message.removeFile(null, attachment).then(() => {
        this.resizeEditor();
      });
    },

    submit() {
      this.isLoading = true;
      const possibleLawyerId = this.selectedRecipients[0].possibleLawyerId;

      const messagePromises = map(this.selectedRecipients, (recipient) => {
        return MessagingService.createMessageFromModal(this.message, [recipient], possibleLawyerId);
      });

      return Promise.all(messagePromises).then(() => {
        this.isLoading = false;
        this.submitted = true;
        MessagingService.refresh();
        if (this.onSend) { this.onSend(); }
      });
    },

    openFileSelect() {
      this.$refs.selectInput.click();
    }
  }
};
</script>
